<script setup lang="ts">
// часть, отвечающая за авторизацию при возврате на страницу из ТГ
const route = useRoute();
const auth = useAuth();
const { isLoggedIn } = storeToRefs(auth);

/**
 * Переход на главную домашнюю страницу
 */
async function goToHome() {
  // const query = route.name === 'auth-register' ? { welcome: 1 } : undefined;
  if (import.meta.client) {
    window.location.reload();
  }
  if (route.name === 'auth-register') {
    await navigateTo({
      path: DEFAULT_HOME,
      query: { welcome: 1 },
    });
  }
}

/**
 * Отслеживаем изменение видимости приложения
 */
const visibility = useDocumentVisibility();
watch(
  visibility,
  (current, previous) => {
    if (current !== 'visible' || (previous !== 'hidden' && previous !== undefined)) return;
    setTimeout(async () => {
      await auth.optimisticAuthIfNotYet();
    }, 1500);
  },
  {
    immediate: true,
  },
);

/**
 * Отслеживаем изменение статуса входа пользователя
 */
watch(
  isLoggedIn,
  async (newVal) => {
    if (newVal) {
      await goToHome();
    }
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <div class="page-layout">
    <slot />
  </div>
</template>
